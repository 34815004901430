import { ref } from 'vue';

import { store } from '@/store/index';
import { readCompany } from '@/store/main/getters';
import {
  dispatchUpdateCompanyTag,
  dispatchCreateCompanyTag,
  dispatchDeleteCompanyTag,
  dispatchGetCompanyTag,
} from '@/store/main/actionsMain';
import { Tag } from '@/interfaces/model/general/tag';

export interface CompanyTagFormData {
  name: string;
  color: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useTagComposable = (): any => {
  const dialog = ref<boolean>(false);
  const isValidForm = ref<boolean>(false);
  const isLoading = ref<boolean>(false);
  const newTagForm = ref<CompanyTagFormData>({
    name: '',
    color: 0,
  });
  const tags = ref<Array<Tag>>([]);

  const resetForm = (): void => {
    newTagForm.value.name = '';
  };

  const getCompanyTag = async (): Promise<void> => {
    tags.value.splice(0);

    const response = await dispatchGetCompanyTag(store, {
      companyId: readCompany(store)['uuid'],
    });
    if (response) {
      tags.value.push(
        ...response.data.map((x) => {
          return {
            name: x.name,
            id: x.id,
            color: x.color,
            editorDialog: false,
            deleteDialog: false,
          };
        })
      );
    }
  };

  const createCompanyTag = async (): Promise<void> => {
    dialog.value = false;

    await dispatchCreateCompanyTag(store, {
      payload: {
        name: newTagForm.value.name,
        color: newTagForm.value.color,
      },
      companyId: readCompany(store)['uuid'],
    });
    await getCompanyTag();
    resetForm();
  };

  const deleteCompanyTag = async (id: number): Promise<void> => {
    dialog.value = false;

    await dispatchDeleteCompanyTag(store, {
      id: id,
    });
    await getCompanyTag();
  };

  const updateCompanyTag = async (
    id: number,
    name: string,
    color: number
  ): Promise<void> => {
    try {
      await dispatchUpdateCompanyTag(store, {
        payload: {
          id: id,
          name: name,
          color: color,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      await getCompanyTag();
    }
  };

  const getJoinedTagName = (index: number) => {
    const tag = tags.value.find((tag: Tag) => tag.id === index);
    return tag ? tag.name : '';
  };

  const getJoinedTagColor = (index: number) => {
    const tag = tags.value.find((tag: Tag) => tag.id === index);
    return tag ? tag.color : '';
  };

  return {
    dialog,
    isValidForm,
    isLoading,
    tags,
    newTagForm,
    resetForm,
    createCompanyTag,
    getCompanyTag,
    deleteCompanyTag,
    updateCompanyTag,
    getJoinedTagName,
    getJoinedTagColor,
  };
};
